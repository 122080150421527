<!-- 兑换门票确认弹窗 -->
<template>
	<div class="exchange-ticket-confirm-wrapper" v-if="show">
		<div class="fadeIn out">
			<div class="exchange-ticket-confirm-all">
				<div class="desc">兑换后不可退还，确定兑换吗？</div>
				<div class="btn-group">
					<div class="btn cancel" @click="close">取消</div>
					<div class="btn confirm" @click="confirm">确定</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import list from '@/api/list';
	export default {
		inject: [
			'openFullLoading',
			'closeFullLoading',
			'showToast'
		],
		props: {
			show: {
				type: Boolean,
				default: false
			},
			id: {// 兑换项id
				type: Number,
				default: -1
			}
		},
		methods: {
			close() {
				this.$emit('close');
			},
			confirm() {
				this.openFullLoading();
				list.buyTicket(this.id).then((res) => {
					this.closeFullLoading();
					if(res.code == 0) {
						this.showToast('兑换成功');
						// 关闭兑换确认弹窗和更新余额交给上层
						this.$emit('exchangeTicketSuccess');
					}
				}).catch(() => { this.closeFullLoading(); })
			}
		}
	}
</script>

<style lang="scss" scoped>
	.exchange-ticket-confirm-wrapper {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.6);
		z-index: 100;
		.out {
			width: 100%;
			height: 100%;
			background: transparent;
			position: relative;
			.exchange-ticket-confirm-all {
                width: 2.95rem;
                height: 1.7rem;
                background: #FFFFFF;
                border-radius: 0.15rem;
                padding-top: 0.48rem;
                display: flex;
                align-items: center;
                flex-direction: column;
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                .desc {
                    font-size: 0.14rem;
                    color: #191A38;
                    line-height: 0.22rem;
                    margin-bottom: 0.36rem;
                }
                .btn-group {
                    display: flex;
                    align-items: center;
                    .btn {
                        width: 1.19rem;
                        height: 0.4rem;
                        border-radius: 0.26rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.15rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        
                    }
                    .btn.cancel {
                        border: 1px solid rgba(0,0,0,0.45);
                        color: rgba(0,0,0,0.45);
                        margin-right: 0.24rem;
                    }
                    .btn.confirm {
                        background: linear-gradient(180deg, #FFD14D 0%, #FB9324 100%);
                        color: #fff;
                    }
                }
            }
		}
	}
</style>