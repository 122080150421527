<template>
  <div id="app">
    <router-view></router-view>
    <toast :text="text" ref="toast" textBgColor="rgba(58,58,58,.9) !important"></toast>
    <full-loading v-show="showFullLoading" :title="fullLoadingText"></full-loading>
    <lack-of-ticket
      :show="showLackOfTicket"
      :showNum="showLackOfTicketNum"
      @close="showLackOfTicket=false"
      @goGetTicket="goGetTicket"></lack-of-ticket>
    <lack-of-ticket-and-never-finish-task
      :show="showLackOfTicketAndNeverFinishTask"
      @close="showLackOfTicketAndNeverFinishTask=false"></lack-of-ticket-and-never-finish-task>
    <exchange-ticket
        :show="showExchangeTicket"
        @close="showExchangeTicket=false"
        @exchange="openExchangeTicketConfirm"
        @openBalanceConfirm="showBalanceConfirm=true"></exchange-ticket>
    <exchange-ticket-confirm
        :show="showExchangeTicketConfirm"
        :id="exchangeId"
        @close="showExchangeTicketConfirm=false" 
        @exchangeTicketSuccess="exchangeTicketSuccess"
        ></exchange-ticket-confirm>
    <balance-confirm
        :show="showBalanceConfirm"
        @close="showBalanceConfirm=false"></balance-confirm>
  </div>
</template>

<script>
import toast from '@/common/components/toast/toast';
import fullLoading from '@/common/components/full-loading/full-loading';
import lackOfTicket from '@/components/popup/glory/lackOfTicket';
import lackOfTicketAndNeverFinishTask from '@/components/popup/glory/lackOfTicketAndNeverFinishTask';
import exchangeTicket from '@/components/popup/glory/exchangeTicket';
import exchangeTicketConfirm from '@/components/popup/glory/exchangeTicketConfirm';
import balanceConfirm from '@/components/popup/glory/balanceConfirm';
import list from '@/api/list';
import { mapMutations, mapState } from 'vuex';
import { returnRem } from '@/common/js/util';
import { sensorsInit } from '@/common/js/sensors';

export default {
  name: 'App',
  provide() {
    return {
      showToast: this.showToast,
      openFullLoading: this.openFullLoading,
      closeFullLoading: this.closeFullLoading,
      updateUserInfo: this.updateUserInfo,
      openLackOfTicket: this.openLackOfTicket,
      openLackOfTicketAndNeverFinishTask: this.openLackOfTicketAndNeverFinishTask
    }
  },
  data() {
    return {
      text: '',
      showFullLoading: false,
      fullLoadingText: '',
      showLackOfTicket: false,
      showLackOfTicketNum: -1,
      showLackOfTicketAndNeverFinishTask: false,
      showExchangeTicket: false,
      showExchangeTicketConfirm: false,
      showBalanceConfirm: false,
      exchangeId: -1

    }
  },
  computed: {
    ...mapState(['globalConfig'])
  },
  mounted() {
    console.log('sjs-1021')
    this.getPaddingTop();
    window.addEventListener('beforeunload', e => {;
      sessionStorage.setItem('sjsrefresh', true);
    });// 监听浏览器刷新

    sensorsInit();
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setPaddingTop']),
    getPaddingTop() {
      // 获取整个系统的padding-top
      try {
          let paddingTop = android.getStatusBarHeight();
          // let paddingTop = 75;
          let rem = returnRem();
          if(paddingTop && !rem) {
            setTimeout(() => {
              this.getPaddingTop();
              return
            }, 500);
          }
          let result = (paddingTop / rem)/4 + 0.08 + 'rem';
          this.setPaddingTop(result)
          // document.getElementsByTagName('html')[0].style.cssText += `padding-top: ${result}`
      } catch(err) {
        // console.log("🚀 ~ file: App.vue:42 ~ mounted ~ err", err)
      }
    },
    /*顶层常用方法*/
    showToast(text) {
      this.text = text;
      this.$refs.toast.show();
    },
    openFullLoading(str) {
      if(str) this.fullLoadingText = str;
      this.showFullLoading = true;
    },
    closeFullLoading() {
      this.showFullLoading = false;
    },
    updateUserInfo() {
      list.getUserInfo().then((res) => {
        this.showFullLoading = false;
        this.setUserInfo(res.data);
      })
    },
    openLackOfTicket(showNum=-1) {
      this.showLackOfTicketNum = showNum;
      this.showLackOfTicket = true;
    },
    openLackOfTicketAndNeverFinishTask() {
      this.showLackOfTicketAndNeverFinishTask = true;
    },
    goGetTicket() {
        this.showLackOfTicket = false;
        // if(this.globalConfig.hasNewerGift) {
        if(this.globalConfig.hasInvete) {
            this.$router.push({
                name: 'GloryGetTicket'
            })
        } else {
            this.showExchangeTicket = true;
        }
    },
    openExchangeTicketConfirm(id) {
      this.showExchangeTicketConfirm = true;
      this.exchangeId = id;
    },
    // 兑换门票成功
    exchangeTicketSuccess() {
      this.showExchangeTicket = false;
      this.showExchangeTicketConfirm = false;
      // 更新门票余额
      setTimeout(() => { this.updateUserInfo(); }, 1200);
    },
  },
  components: {
    toast,
    fullLoading,
    lackOfTicket,
    lackOfTicketAndNeverFinishTask,
    exchangeTicket,
    exchangeTicketConfirm,
    balanceConfirm
  }
}
</script>

<style>
@import './style/common.scss';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
body,html{
  height: 100%;
    padding: 0;
    margin: 0;
    touch-action: manipulation;
  }

  div, p, h1, h2, h3, h4, h5, h6, ul, li {
      padding: 0;
      margin: 0;
  }

  #app{
    width: 100%;
    height: 100%;
    position: relative;
    transform: translate(0,0);
    /* background: #938698; */
    background: #151d48;
    margin: 0 auto;
    overflow: hidden;
  }

  * {
    box-sizing: border-box;
    user-select: auto;
    -webkit-overflow-scrolling: touch;
  }
  body{
    background: transparent;
  }
* {
    box-sizing: border-box;
    user-select: auto;
    -webkit-overflow-scrolling: touch;
    text-align: left;
  }
</style>
