import confrimDialog from './confirm.vue'
import Vue from 'vue'

/**
 * 打开对话框，可多次打开
 * @param {import('./confirmType').ConfirmType} config 对对话框的配制对象
 * @returns {Promise<import('./confirmType').ConfirmPromise>}
 */
function confirm (config) {
  let node = document.createElement('div')
  let confirmVue = Vue.extend(confrimDialog)
  /**
   * @type {Vue}
   */
  let instance = new confirmVue({
    el: node,
    message: config.message,
    buttons: config.buttons
  })
  instance.$mount()

  Object.assign(instance, config)

  /**
   * @type {Promise<import('./confirmType').ConfirmPromise>}
   */
  let promise = new Promise((resolve, reject) => {
    try {
      instance.$on('confirm', (item, index) => {
        if (document.body.contains(instance.$el)) {
          document.body.removeChild(instance.$el)
        }
        resolve({
          item,
          index
        })
        instance.$off('confirm')
      })
    } catch (e) {
      reject(e)
    }
  })

  document.body.appendChild(instance.$el)

  return promise
}

export default confirm