import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

const state = {
    userInfo: {},
    groupUnReadCount: 0,
    globalConfig: {},
    checkPopup: false,
    bottomList: [], // 底部菜单栏
    configData: {},
    paddingTop: 0,
    championGameId: -1
    // transitionName: ''
}

const mutations = {
    // 设置用户信息
    setUserInfo(state, n) {
        state.userInfo = n;
        return state.userInfo;
    },
    // 设置全局的群组未读消息总数（普通群聊且开了提醒的）
    setGroupUnReadCount(state, n) {
        state.groupUnReadCount = n;
        return state.groupUnReadCount;
    },
    // 设置全局配置
    setGlobalConfig(state, n) {
        state.globalConfig = n;
        return state.globalConfig;
    },
    // 设置检查首页弹出弹窗状态
    setCheckPopup(state, n) {
        state.checkPopup = n;
        return state.checkPopup
    },
    // 设置底部菜单栏
    setBottomList(state, n) {
        state.bottomList = n;
        return state.bottomList
    },
    // 设置常用配置数据
    setConfigData(state, n) {
        state.configData = n;
        return state.configData
    },
    // setTransitionName(state, n) {
    //     state.transitionName = n;
    //     return state.transitionName;
    // }
    // 设置头像框到用户信息里面
    setUserAvatarBorder(state, n) {
        let obj = state.userInfo;
        obj.avatarBorder = n;
        state.userInfo = obj;
        return state.userInfo
    },
    setPaddingTop(state, n) {
        state.paddingTop = n;
        return state.paddingTop
    },
    setChampionGameId(state, n) {
        state.championGameId = n;
        return state.championGameId
    }
}
// const getters = {
// 	unread100(state) {
// 		return +state.groupUnReadCount + 100;
// 	}
// }
export default new Vuex.Store({
	state,
	mutations,
	// getters,
	// actions
})