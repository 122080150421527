<template>
  <div
    class="background-xw-dialog"
    id="background-xw-dialog"
    v-bind:class="[orientation == 2 ? 'horizontal' : 'landscape']"
    @click="onBackgroundClick"
  >
    <div
      class="dialog-xw-dialog"
      id="dialog-xw-dialog"
      @click.stop
    >
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'XwDialog',
  props:{
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      orientation: 1
    }
  },
  mounted () {
    // this.resize()
    window.addEventListener('resize', this.resize)
  },
  methods:{
    onBackgroundClick (){
      this.$emit('close')
    },
    resize () {
      // 竖屏
      if (window.orientation == 180 || window.orientation == 0) {
        this.orientation = 1
      }
      // 横屏
      if (window.orientation == 90 || window.orientation == -90) {
        this.orientation = 2
      }
    }
  }
}
</script>

<style scoped>
  .background-xw-dialog{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999;
    background: rgba(0, 0, 0, 0.1);
  }
  .horizontal {
    transform: rotate(90deg)
  }
  .landscape {
    transform: rotate(0deg)
  }
  /* 竖屏 */
  @media screen and (orientation:portrait) {
    .dialog-xw-dialog{
      opacity: 0.85;
      background: #422F2A;
      /* border-radius: 8px; */
      border-radius: 0.03rem;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      /* padding: 9.6vw 8.8vw; */
      padding: 0.08rem 0.15rem;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
  /* 横屏 */
  @media screen and (orientation:landscape) {
    .dialog-xw-dialog{
      opacity: 0.85;
      background: #422F2A;
      /* border-radius: 8px; */
      border-radius: 0.03rem;
      font-size: 14px;
      color: #FFFFFF;
      letter-spacing: 0;
      /* padding: 9.6vh 8.8vh; */
      padding: 0.08rem 0.15rem;
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
    }
  }
</style>
