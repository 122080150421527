<!-- 收下门票弹窗 -->
<template>
	<div class="receive-ticket-wrapper" v-if="show">
		<div class="fadeIn out">
			<div class="receive-ticket-all bgs100">
				<div class="title bgs100"></div>
				<div class="content-box">
					<div class="firework bgs100"></div>
					<div class="ticket bgs100"></div>
					<div class="name">门票1张</div>
				</div>
				<div class="btn bgs100" @click="close"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			}
		},
		methods: {
			close() {
				this.$emit('close');
			}
		}
	}
</script>

<style lang="scss" scoped>
	.receive-ticket-wrapper {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.6);
		z-index: 11;
		.out {
			width: 100%;
			height: 100%;
			background: transparent;
			position: relative;
			.receive-ticket-all {
				width: 3.37rem;
				height: 3.37rem;
				background-image: url('https://osscdn.zhuayoubao.com/sjs-h5-image/2024-01/glory/receive-ticket-shade%402x.png');
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-50%, -50%);
				display: flex;
				align-items: center;
				justify-content: center;
				.title {
					width: 2.38rem;
					height: 0.91rem;
					background-image: url('https://osscdn.zhuayoubao.com/sjs-h5-image/2024-01/glory/receive-ticket-title%402x.png');
					position: absolute;
					top: -0.02rem;
					left: 50%;
					transform: translate(-50%, 0);
				}
				.content-box {
					width: 1.9rem;
					height: 1.88rem;
					padding-top: 0.44rem;
					display: flex;
					align-items: center;
					flex-direction: column;
					position: relative;
					.firework {
						width: 1.9rem;
						height: 1.88rem;
						background-image: url('https://osscdn.zhuayoubao.com/sjs-h5-image/2024-01/glory/receive-ticket-firework%402x.png');
						top: 0;
						left: 0;
						z-index: -1;
					}
					.ticket {
						width: 1rem;
						height: 1rem;
						background-image: url('https://osscdn.zhuayoubao.com/sjs-h5-image/2024-01/glory/receive-ticket-ticket%402x.png');
						margin-bottom: 0.1rem;
					}
					.name {
						font-size: 0.19rem;
						font-family: PingFangSC, PingFang SC;
						font-weight: 600;
						color: #FFFFFF;
						line-height: 0.27rem;
					}
				}
				
				.btn {
					width: 1.58rem;
					height: 0.43rem;
					background-image: url('./../../../assets/glory/receive-ticket-btn@2x.png');
					position: absolute;
					bottom: 0.13rem;
					left: 50%;
					transform: translate(-50%, 0);
				}
			}
		}
	}
</style>