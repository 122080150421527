<template>
  <div
    class="confirm-warpper"
    @click.stop
  >
    <div class="dialog">
      <div class="content">
        {{ message }}
      </div>
      <div class="buttons">
        <div 
          v-for="(item, index) in buttons"
          :key="index"
          class="button"
          :class="{'button-ok': item.type === 'confirm','button-normal':item.type === 'normal'}"
          @click="onClick(item, index)"
        >
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Confirm',
  props:{
    message: {
      type: String,
      required: false,
      default: ''
    },
    buttons: {
      type: Array,
      required: false,
      default () { 
        return [{
          text: '取消',
          type: 'normal'
        }, {
          text: '确定',
          type: 'confirm'
        }]
      }
    }
  },
  methods:{
    /**
     * @param {{text:String, type: 'normal' | 'confirm'}} item
     * @param {Number} index
     */
    onClick (item, index){
      this.$emit('confirm', item, index)
    }
  }
}
</script>

<style scoped>
.confirm-warpper{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(14,3,0,0.80);
  z-index: 9999999;
}

.dialog{
  background: #FFF;
  margin: 0 70px;
  min-height: 117px;
  position: relative;
  top: 26%;
  border-radius: 10px;
}

.content{
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #444444;
  letter-spacing: 0;
  line-height: 17px;
  padding: 35px 20px 26px 20px;
  text-align: center;
}

.buttons{
  height: 52px;
  display: flex;
  border-top: 0.5px solid #EBE8E8;
}

.button{
  width: 100%;
  text-align: center;
  align-items: center;
  font-family: PingFangSC-Regular;
  font-size: 14px;;
  letter-spacing: 0;
  line-height: 14px;
  line-height: 52px;
  border-right: 0.5px solid #EBE8E8;
  cursor: default
}

.button:last-of-type{
  border: none;
}

.button-ok{
  color: #FE4D4D;
}
.button-normal{
  color: #BAADAD;
}
</style>
