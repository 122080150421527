import 'babel-polyfill'

import Vue from 'vue'
import Es6Promise from 'es6-promise'
import App from './App.vue'
import './networking'
import router from './router'
import { Field, List, Uploader } from 'vant'
import 'vant/lib/index.css'
import store from './vuex/store';
import ResizeObserver from 'resize-observer-polyfill';
// import ImageCompressor from "image-compressor.js"
import VueClipboard from 'vue-clipboard2'


// 挂载到window上，因为插件内是使用的new语法 会直接在window上找
window.ResizeObserver = ResizeObserver;

// import VConsole from 'vconsole';
// const vConsole = new VConsole();// 初始化vconsole

require('es6-promise').polyfill()
Es6Promise.polyfill()

// console.log(ResizeObserver)

Vue.config.productionTip = false
Vue.use(VueClipboard)
Vue.use(Field)
Vue.use(List)
Vue.use(Uploader);
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


function setRem() {
  var whdef = 100/375  //  表示1920的设计图,使用100PX的默认值
  var bodyWidth = document.getElementById('app').getBoundingClientRect().width;// 当前窗口的宽度
  var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  
  document.getElementsByTagName('html')[0].style.fontSize = rem + 'px';
  document.getElementsByTagName('html')[0].style.opacity = 1;
}

window.addEventListener('load', setRem);
window.addEventListener('resize', setRem);