import axios from 'axios'
import dialog from '../uicomponents/index'
import { Base64 } from 'js-base64';

axios.interceptors.response.use(response => {
  try {
    // 爪游宝接口
    if(response.config.url == "/common/uploadImg" || JSON.parse(response.config.data).zyb) {
      return handleZybData(response.data, response.request.responseURL);
    } else {
      return handleData(response.data, response.request.responseURL);
    }
  } catch(err)  {
    // 赏金赛接口
    return handleData(response.data, response.request.responseURL);
  }
}, error => {
  
})

function handleData(data, url) {
  if (data.code == 0) {
      return data
  } else {// token失效，重新登录的操作
    dialog.dialog.alert(data.view, url);
  }
}


// 处理爪游宝接口返回的数据
function handleZybData(data, url) {
  if (data.code == 0) {
      return data
  } else if(data.code == 401) {// token失效，重新登录的操作
      dialog.dialog.alert(data.view);
      localStorage.clear();
      sessionStorage.clear();
      // setTimeout(() => {
      //   location.href = `${location.origin}/game`;
      // }, 1000);
  } else {
      dialog.dialog.alert(data.view)
      return Promise.reject(data.view)
  }
}