<!-- 一键匹配时提示门票不足 -->
<template>
	<div class="lack-of-ticket-wrapper" v-if="show">
		<div class="fadeIn out">
			<div class="lack-of-ticket-all bgs100">
				<div class="close bgs100" @click="close"></div>
				<div class="title">门票不足</div>
				<div class="content">
					<p>每场比赛需花费<span>{{showNum !== -1 ? showNum : 5}}张门票</span>参与<br/>领取游戏任务奖励即可获得门票哦~</p>
				</div>
					
				<div class="btn" @click="goPickGame">去挑选游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { returnIsIOS } from '@/common/js/util';
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
			showNum: {
				type: Number,
				default: 0
			},
		},
		methods: {
			close() {
				this.$emit('close');
			},
			goPickGame() {
				if(returnIsIOS()) {
					this.close();
					window.webkit.messageHandlers.gotoRecommond.postMessage('');
				} else {
					try {
						android.jump('HOME');
					} catch(err) {
						console.log('h5退出');
						window.parent.postMessage({
							event:'sjs-exit',
							data: ''
						}, '*')
					}
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.lack-of-ticket-wrapper {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.6);
		z-index: 100;
		.out {
			width: 100%;
			height: 100%;
			background: transparent;
			position: relative;
			.lack-of-ticket-all {
				width: 2.95rem;
				background: #fff;
				border-radius: 0.15rem;
				padding: 0.24rem 0 0.24rem;
				display: flex;
				align-items: center;
				flex-direction: column;
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-50%, -50%);
				.close {
					width: 0.24rem;
					height: 0.24rem;
					background-image: url('./../../../assets/glory/close-single-black@2x.png');
					position: absolute;
					top: 0.12rem;
					right: 0.14rem;
				}
				.title {
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					font-size: 0.16rem;
					color: #191A38;
					line-height: 0.16rem;
					margin-bottom: 0.28rem;
				}
				.content {
					p {
						font-family: PingFangSC, PingFang SC;
						font-weight: 400;
						font-size: 0.14rem;
						color: #191A38;
						line-height: 0.22rem;
						text-align: center;
						margin-bottom: 0.3rem;
						span {
							color: #FF4646;
						}
					}
				}
				.btn {
					width: 1.48rem;
					height: 0.4rem;
					background: #8B95F7;
					border-radius: 0.26rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					font-size: 0.15rem;
					color: #FFFFFF;
				}
			}
		}
	}
</style>