// // 返回参数 - 普通格式（?game_id=81&pid=154）
export function getParams(params) {
    let arr = params.slice(1).split("&");
    let obj = {};
    for (var i = 0; i < arr.length; i++) {
        obj[arr[i].split("=")[0]] = arr[i].split("=")[1];
    }
    return obj;
}

// 判断是否为空
export function isNull(ele) {
    return !ele
}
// 返回背景图片样式
export function getBgImg(src) {
    return  { backgroundImage: `url('${src}')` }
}

// rem和px转换
export function returnRem() {
    var whdef = 100/375  //  表示1920的设计图,使用100PX的默认值
    // var bodyWidth = document.body.clientWidth;// 当前窗口的宽度
    var bodyWidth = document.getElementById('app').getBoundingClientRect().width;// 当前窗口的宽度
    var rem = bodyWidth * whdef;// 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
    return rem;
}

// 验证手机号
export function checkMobile(mobile){ 
    let reg =/^1[3456789]\d{9}$/;
    return reg.test(mobile);
}

// \n转化为<br/>的html转化
export function n2br(text) {
    if(text) return text.replace(/\n/g,'<br/>')
}

// 返回是否在安卓内部
export function returnIsAndroid() {
    try {
        android.getPackageName();
        return true
    } catch(err) {
        return false
    }
}

// 返回是否在ios内部
export function returnIsIOS() {
    let device = sessionStorage.getItem('device');
    if(device && device == 'ios') {
        return true;
    } else {
        return false;
    }
}