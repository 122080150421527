import Vue from 'vue'
import alert from './alert.vue'
import { isNull } from '@/common/js/util';

/**
 * @type {Vue}
 */
let instance = null
/**
 * @type {HTMLElement}
 */
let node = null

/**
 * 弹出对话框
 * @param {String} message 提示文字
 * @returns {Promise<String>} 返回一个Promise
 */
function dialog (message, url) {
  if (isNull(node)) {
    node = document.createElement('div')
  }
  if (isNull(instance)) {
    let dialogAlert = Vue.extend(alert)    
    instance = new dialogAlert({
      el: node,
      text: message,
      url
    })
    instance.$mount()
  }

  let autoClose = function () {
    instance.$emit('close')
  }

  Object.assign(instance, {
    text: message,
    url
  })

  document.body.appendChild(instance.$el)

  let promise = new Promise((resolve, reject) => {
    try {
      // let ss = url.indexOf('/withdraw/withdraw') == -1 ? 1500 : 10000;
      // let time = setInterval(autoClose, ss)
      let time = setInterval(autoClose, 1500)
      instance.$on('close', () => {
        clearInterval(time)
        if (document.body.contains(instance.$el)) {
          document.body.removeChild(instance.$el)
        }
        resolve(message)
        instance.$off('close')
      })
    } catch (e) {
      if (reject) {
        reject(e)
      }
    }
  })
  return promise
}

export default dialog
