import axios from 'axios';

function getCommonParam() {
  let params = {}
  return params;
}


const list = {
  // 路由前置地址
  homeRouterPage() {
    let params = {};
    return axios.get('/home/routerPage', {params})
  },
  // 首页游戏列表
  homeHomeGameList(gameMode, page, pagesize) {
    let params = {
      gameMode, page, pagesize
    }
    return axios.get('/home/homeGameList', {params})
  },
  // 首页用户信息
  getUserInfo() {
    let params = {};
    return axios.get('/home/homeUserInfo', {params})
  },
  // 渠道、配置接口
  homeInviteRules() {
    let params = {};
    return axios.get('/home/inviteRules', {params})
  },
  // 比赛详情接口
  gameGameDetail(gameid) {
    let params = { gameid };
    return axios.get('/game/gameDetail', {params})
  },

  gameGameJoinResult(id) {
    let params = {id};
    return axios.get('/game/gameJoinResult', {params})
  },

  // 比赛结果
  gameGameResult(gameid) {
    let params = { gameid }
    return axios.get('/game/gameResult', {params})
  },
  // 游戏参赛选手
  gameGameUsers(gameid) {
    let params = { gameid };
    return axios.get('/game/gameUsers', {params})
  },
  // 游戏报名
  gameJoinGame(gameid, teamId=null, childId=null) {
    let params = { gameid };
    if(teamId) params.teamId = teamId;
    if(childId) params.childId = childId;
    return axios.get('/game/joinGame', {params})
  },
  // 预约报名
  gameJoinGamePre(gameid) {
    let params = { gameid }
    return axios.get('/game/joinGamePre', {params})
  },
  // 预约提醒
  gameJoinGameRemind() {
    let params = {}
    return axios.get('/game/joinGameRemind', {params})
  },
  // 参赛记录
  gameMyJoinGames(page, pagesize) {
    let params = {
      page, pagesize
    };
    return axios.get('/game/myJoinGames', {params})
  },
  // 报名满了后，推荐的其他游戏列表
  gameRecommendGames(gameMode, page, pagesize) {
    let params = {
      gameMode, page, pagesize
    };
    return axios.get('/game/recommendGames', {params})
  },
  // 绑定游戏昵称
  userBindNickname(nickname) {
    let params = { nickname };
    return axios.post('/user/bindNickname', params)
  },
  // 刷新缓存
  userRefreshUserCache() {
    let params = {};
    return axios.get('/user/refreshUserCache', {params})
  },
  // 我的赏金
  userUserMoneyFlux(ftype, page, pagesize) {
    let params = {
      ftype, page, pagesize
    };
    return axios.get('/user/userMoneyFlux', {params})
  },
  // 我的门票
  userUserTicketFlux(ftype, page, pagesize) {
    let params = {
      ftype, page, pagesize
    };
    return axios.get('/user/userTicketFlux', {params})
  },
  // 买门票
  buyTicket(ticketId) {
    let params = { ticketId };
    return axios.get('/user/buyTicket', {params})
  },
  // 用户渠道账户余额
  userChannelMoney() {
    let params = {};
    return axios.get('/user/channelMoney', {params})
  },
  // 获取爪游宝渠道id和token
  userChildAndToken() {
    let params = {}
    return axios.get('/user/chidAndToken', {params})
  },
  // 我的邀请统计项
  userMyInviteInfo() {
    let params = {};
    return axios.get('/user/myInviteInfo', {params})
  },
  // 我的邀请用户列表
  userMySubUsers(page, pagesize) {
    let params = {
      page, pagesize
    };
    return axios.get('/user/mySubUsers', {params})
  },
  // 用户门票选项
  // userTicketConfig(ticketId) {
  //   let params = { ticketId };
  //   return axios.get('/user/ticketConfig', {params})
  // },
  // 用户渠道账户余额
  userChannelMoney() {
    let params = {};
    return axios.get('/user/channelMoney', {params})
  },
  // 绑定微信
  withdrawBindWechat(code) {
    let params = { code };
    return axios.get('/withdraw/bindWechat', {params})
  },
  // 实名和绑定公众号情况查询
  withdrawQueryUserAuth() {
    let params = {};
    return axios.get('/withdraw/queryUserAuth', {params})
  },
  // 实名认证
  withdrawRealAuth(id, name) {
    let params = { 
      identifer: id,
      realName: name
    }
    return axios.post('/withdraw/realAuth', params)
  },
  // 提现
  withdrawWithdraw(money, type=1, accountId='') {
    let params = {configId: 2, money, type, accountId };
    return axios.get('/withdraw/withdraw', {params})
  },
  // 查询用户能提多少
  withdrawWithdrawPre() {
    let params = {};
    return axios.get('/withdraw/withdrawPre', {params})
  },
  // 绑定支付宝
  withdrawBindAliPay(payeeName, payeeAccount, idNumber, smsCode) {
    let params = {
      payeeName, payeeAccount, idNumber, smsCode
    }
    return axios.get('/withdraw/bindAliPay', {params})
  },
  // 刷新 - 未用
  homeRefreshSysParam() {
    let params = {};
    return axios.get('/home/refreshSysParam', {params})
  },
  // 发送验证码
  withdrawGetSmsCode(phone) {
    let params = { phone };
    return axios.get('/withdraw/getSmsCode', {params})
  },
  // 是否可领公众号门票
  userHasTicketToReceive() {
    let params = {};
    return axios.get('/user/hasTicketToReceive', {params})
  },
  // 领关注公众号门票
  userReceiveSubscribeTicket() {
    let params = {};
    return axios.get('/user/receiveSubscribeTicket', {params})
  },
  // 匹配（接口1）
  matchingStart() {
    let params = {};
    return axios.get('/matching/start', {params})
  },
  // 匹配结果轮询（接口2）
  matchingWatching(roomId) {
    let params = roomId ? { roomId } : {};
    return axios.get('/matching/watching', {params})
  },
  // 准备（接口3）
  matchingReady(roomId) {
    let params = { roomId };
    return axios.get('/matching/ready', {params})
  },
  // 查询游戏信息（接口4） 
  matchingGameInfo(roomId) {
    let params = { roomId };
    return axios.get('/matching/matchGameInfo', {params})
  },
  // 超时（接口5）
  matchingTimeout(roomId) {
    let params = { roomId };
    return axios.get('/matching/prepareTimeout', {params})
  },
  // 取消匹配（接口6）
  matchingCancel() {
    let params = {};
    return axios.get('/matching/cancel', {params})
  },
  // 排行榜
  homeRankList() {
    let params = {};
    return axios.get('/home/rankList', {params})
  },
  // 举报
  userReportUser(userid, gameId, repUserid, repType, reason='', imgs) {
    let params = {
      userid, gameId, repUserid, repType, reason, imgs
    }
    return axios.post('/user/reportUser', params)
  },
  // 图片上传
  commonUploadImg(file, type=1) {
    let params = {file}
    if(type) params.type = type;
    return axios.post('/uploadImg', params)
  },
  // 送门票
  userGiveTicket(amount, toAppSign) {
    let params = { amount, toAppSign }
    return axios.get('/user/giveTicket', {params})
  },
  // 淘汰赛轮次
  knockoutRoundInfo(gameid) {
    let params = { gameid };
    return axios.get('/knockout/roundInfo', {params})
  },
  // 报名状态
  knockoutJoinGameStatus(gameid) {
    let params = { gameid };
    return axios.get('/knockout/joinGameStatus', {params})
  },
  // 淘汰赛报名信息
  knockoutInfo(gameid) {
    let params = { gameid };
    return axios.get('/knockout/info', {params})
  },
  // 淘汰赛队伍信息
  knockoutTeamInfo(gameid) {
    let params = { gameid };
    return axios.get('/knockout/teamInfo', {params})
  },
  // 查询当前进行的淘汰赛
  knockoutGetGame() {
    let params = {};
    return axios.get('/knockout/getGame', {params})
  },
  // 绑定微信
  userBindWechat(packageName, code) {
    let params = { packageName, code };
    return axios.post('/user/bindWechat', params)
  },
  // 绑定QQ
  userBindQQ(openId, realName, head='') {
    let params = { openId, realName, head };
    return axios.post('/user/bindQQ', params)
  },
  // 淘汰赛比赛结果
  knockoutResult(roundId, nodeId, gameId) {
    let params = { roundId, nodeId, gameId };
    return axios.get('/knockout/result', {params})
  },
  // 门票兑换选项（新）
  userTicketConfig() {
    let params = {};
    return axios.get('/user/ticketConfig', {params})
  },
  // 合成碎片
  ticketPieceAssemblePieces() {
    let params = {};
    return axios.get('/ticketPiece/assemblePieces', {params})
  }
}

export default list