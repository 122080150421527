var sensors = require('sa-sdk-javascript'); 

// 先 import 主 SDK，后 import plugin。先调用 use，后调用 init

// 神策初始化方法
export function sensorsInit() {
    sensors.init({
        show_log: false,
        // server_url: 'http://hongtongtong.datasink.sensorsdata.cn/sa?project=default&token=1e141934caf4bc24',
        server_url: 'https://hongtongtong.datasink.sensorsdata.cn/sa?project=production&token=1e141934caf4bc24',// 正式环境
        is_track_single_page:true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
        is_track_device_id:true,
        app_js_bridge:true,
        use_client_time:true, 
        send_type:'beacon',
        heatmap: {
            //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
            clickmap:'default',
            //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
            scroll_notice_map:'default',
            // scroll_delay_time: 1000, 
	        // scroll_event_duration: 18, //单位秒，预置属性停留时长 event_duration 的最大值。默认5个小时，也就是300分钟，18000秒。
            collect_tags: {
                div: {
                    max_level: 3 // 默认是 1，即只支持叶子 div。可配置范围是 [1, 2, 3],非该范围配置值，会被当作 1 处理。
                },
                span: true,
                img: true
            }
        }
    });

    // 设置公共参数，初始化后直接设置一次
    sensors.registerPage({ platform_type: 'h5' });

    sensors.quick('autoTrack'); //用于采集 $pageview 事件。

    var presetProperties = sensors.getPresetProperties();
    // console.log("🚀 ~ file: sensors.js:36 ~ sensorsInit ~ presetProperties:", presetProperties)
}

// 神策登录方法
export function sensorsLogin(id) {
    sensors.login(id);
}

// 神策埋点方法
export function sensorsTrack(name, obj) {
    sensors.track(name, obj);
}