<!-- 一键匹配时提示门票不足 -->
<template>
	<div class="lack-of-ticket-and-never-finish-task-wrapper" v-if="show">
		<div class="fadeIn out">
			<div class="lack-of-ticket-all bgs100">
				<div class="close bgs100" @click="close"></div>
				<div class="title">门票不足</div>
				<div class="content">领取游戏任务奖励即可获得门票哦~</div>
				<div class="btn" @click="goZybIndex">去挑选游戏</div>
			</div>
		</div>
	</div>
</template>

<script>
	// import { returnIsIOS } from '@/common/js/util';
	export default {
		props: {
			show: {
				type: Boolean,
				default: false
			},
		},
		methods: {
			close() {
				this.$emit('close');
			},
			goZybIndex() {
				// if(returnIsIOS()) {
				// 	this.close();
				// 	window.webkit.messageHandlers.gotoRecommond.postMessage('');
				// } else {
					try {
						android.jump('HOME');
					} catch(err) {
						console.log('h5退出');
						window.parent.postMessage({
							event:'sjs-exit',
							data: ''
						}, '*')
					}
				// }
			}
		}
	}
</script>

<style lang="scss" scoped>
	.lack-of-ticket-and-never-finish-task-wrapper {
		width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.6);
		z-index: 100;
		.out {
			width: 100%;
			height: 100%;
			background: transparent;
			position: relative;
			.lack-of-ticket-all {
				width: 3.08rem;
				background: #FFFFFF;
				border-radius: 0.14rem;
				padding: 0.42rem 0 0.29rem;
				display: flex;
				align-items: center;
				flex-direction: column;
				position: absolute;
				top: 45%;
				left: 50%;
				transform: translate(-50%, -50%);
				.close {
					width: 0.24rem;
					height: 0.24rem;
					background-image: url('./../../../assets/glory/close-single-black@2x.png');
					position: absolute;
					top: 0.12rem;
					right: 0.14rem;
				}
				.title {
					font-size: 0.17rem;
					font-family: PingFangSC, PingFang SC;
					font-weight: 600;
					color: #191A38;
					line-height: 0.17rem;
					margin-bottom: 0.17rem;
				}
				.content {
					font-size: 0.14rem;
					font-family: PingFangSC, PingFang SC;
					font-weight: 400;
					color: #191A38;
					line-height: 0.23rem;
					margin-bottom: 0.23rem;
				}
				.btn {
					width: 1.62rem;
					height: 0.42rem;
					background: #8B94F7;
					border-radius: 0.21rem;
					display: flex;
					align-items: center;
					justify-content: center;
					font-size: 0.14rem;
					font-family: PingFangSC, PingFang SC;
					font-weight: 500;
					color: #FFFFFF;
				}
			}
		}
	}
</style>