import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const Glory = () => import('@/pages/glory');
const GloryMatchRecord = () => import('@/pages/matchRecord');
const GloryMatchResult = () => import('@/pages/matchResult');
const GloryMoney = () => import('@/pages/money');
const GloryTicket = () => import('@/pages/ticket');
const GiveTicket = () => import('@/pages/giveTicket');
const GloryMatchRule = () => import('@/pages/matchRule');
const GloryMatch = () => import('@/pages/match');
const GloryMatchMember = () => import('@/pages/matchMember');
const GloryHowToJoin = () => import('@/pages/howToJoin');
const GloryGetTicket = () => import('@/pages/getTicket');
const GloryGetTicketRule = () => import('@/pages/getTicketRule');
const GloryMatchingPage = () => import('@/pages/matchingPage');
const Withdraw = () => import('@/pages/withdraw');
const Bind = () => import('@/pages/bind');
const Setting = () => import('@/pages/setting');
const Iframe = () => import('@/pages/iframe');
const Realname = () => import('@/pages/realname');
const BindGzh = () => import('@/pages/bindGzh');
const Rank = () => import('@/pages/rank');
const Report = () => import('@/pages/report');
const ChampionMatch = () => import('@/pages/champion/match');
const ChampionCourse = () => import('@/pages/champion/course');
const ChampionMatchDetail = () => import('@/pages/champion/matchDetail');
const ChampionTeam = () => import('@/pages/champion/team');

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/index'
    }, {
      path: '/index',
      name: 'Glory',
      component: Glory,
      children: [{
          path: 'matchRecord',
          name: 'GloryMatchRecord',
          component: GloryMatchRecord,
          children: [{
              path: 'matchResult',
              name: 'GloryMatchResult',
              component: GloryMatchResult,
              meta: { trueComponent: 'GloryMatchResult' },
              children: [{
                path: 'report',
                name: 'GloryMatchResultReport',
                component: Report
              }]
          }]
      }, {
          path: 'money',
          name: 'GloryMoney',
          component: GloryMoney,
          children: [{
            path: 'withdraw',
            name: 'Withdraw',
            component: Withdraw,
            children: [{
              path: 'bind',
              name: 'Bind',
              component: Bind
            }, {
              path: 'realname',
              name: 'Realname',
              component: Realname
            }]
          }]
      }, {
          path: 'ticket',
          name: 'GloryTicket',
          component: GloryTicket,
          children: [{
            path: 'giveTicket',
            name: 'GiveTicket',
            component: GiveTicket
          }]
      }, {
          path: 'matchRule',
          name: 'GloryMatchRule',
          component: GloryMatchRule
      }, {
          path: 'match',
          name: 'GloryMatch',
          component: GloryMatch,
          children: [{
              path: 'member',
              name: 'GloryMatchMember',
              component: GloryMatchMember
          }, {
              path: 'howToJoin',
              name: 'GloryHowToJoin',
              component: GloryHowToJoin
          }, {
            path: 'report',
            name: 'Report',
            component: Report
          }]
      }, {
          path: 'getTicket',
          name: 'GloryGetTicket',
          component: GloryGetTicket,
          children: [{
              path: 'rule',
              name: 'GloryGetTicketRule',
              component: GloryGetTicketRule
          }, {
            path: 'bindGzh',
            name: 'BindGzh',
            component: BindGzh
          }]
      }, {
        path: 'setting',
        name: 'Setting',
        component: Setting,
        children: [{
          path: 'iframe',
          name: 'Iframe',
          component: Iframe
        }]
      }, {
        path: 'matchingPage',
        name: 'MatchingPage',
        component: GloryMatchingPage
      }, {
        path: 'rank',
        name: 'Rank',
        component: Rank
      }, {
        path: 'championMatch',
        name: 'ChampionMatch',
        component: ChampionMatch
      }, {
        path: 'championTeam',
        name: 'ChampionTeam',
        component: ChampionTeam
      }, {
        path: 'championCourse',
        name: 'ChampionCourse',
        component: ChampionCourse,
        children: [{
          path: 'championMatchDetail',
          name: 'ChampionMatchDetail',
          component: ChampionMatchDetail
        }, {
          path: 'matchResult',
          name: 'ChampionMatchResult',
          component: GloryMatchResult,
          meta: { trueComponent: 'GloryMatchResult' }
        }]
      }]
    }
  ]
})

router.beforeEach((to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
