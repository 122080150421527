import axios from 'axios';

function getCommonParamNew() {
  let obj = sessionStorage.getItem('zybRequestParam');
  let device = sessionStorage.getItem('device');
  let params = {
    zyb: true,
    channel: obj ? JSON.parse(sessionStorage.getItem('zybRequestParam')).channelId : '1',
    gameEnvironment: device && device == 'ios' ? 2 : 1// 1是h5,2是ios
  }
  return params;
}

const list = {
  // 图片上传
  commonUploadImg(file, type=1) {
    let params = {file}
    if(type) params.type = type;
    return axios.post('/common/uploadImg', params)
  },
  // 获取系统配置
  systemConfig(key) {
    let params = {
      key, ...getCommonParamNew()
    }
    return axios.get('/system/config', {params})
  },
  // 分享者的用户信息
  shareShareUser(masterId="") {
    let params = getCommonParamNew();
    if(masterId) params.masterId = masterId;
    return axios.get('/share/shareUser', {params})
  },
  // 分享海报
  shareSharePosters() {
    let params = getCommonParamNew();
    return axios.get('/share/sharePosters', {params})
  },
  // 获取头像和头像框
  userGetUserHead() {
    let params = getCommonParamNew();
    return axios.get('/user/getUserHead', {params})
  },
  // 赏金赛视频回调
  userActionMobaCallback(requestId, transactionId) {
    let params = { requestId, transactionId, ...getCommonParamNew() };
    return axios.get('/userAction/moba/callback', {params})
  }
}

export default list