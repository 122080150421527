<template>
  <div class='exchange-ticket-wrapper' v-show="show" @click.self="close">
    <div class="exchange-ticket-box" :class="[showBox ? 'out' : 'in']">
        <!-- <div class="give-ticket" @click="goGiveTicket">
            <span class="text">赠送门票</span>
            <span class="more bgs100"></span>
        </div> -->
        <div class="balance">我的余额：<span>{{data.money | zeroFilter }}</span>{{data.unit}}</div>
        <div class="item" v-for="(item, index) in list" :key="index">
            <div class="left">
                <div class="icon bgs100"></div>
                <span>门票x{{item.amount}}</span>
            </div>
            <div class="right">
                <div class="btn" @click="exchange(item)">{{item.price | zeroFilter }}{{data.unit}}</div>
                <span>今日剩余：{{item.times}}次</span>
            </div>
        </div>
        <template v-if="is_android">
            <div class="item item2" v-for="(item, index) in list2" :key="index + list.length">
                <div class="icon bgs100"></div>
                <div class="content">
                    <div class="name">{{item.title}}</div>
                    <div class="desc">{{item.subTitle}}</div>
                </div>
                <div class="right">
                    <div class="btn" :class="{'get-pieces': item.state == 0, 'go-on-tomorrow': item.state == 2}"
                    @click="videoBtnClick(item)">{{item.state == 0 ? '获取碎片' : item.state == 1 ? '合成' : '请明日继续'}}</div>
                    <span>拥有碎片：{{item.piecesNum}}</span>
                </div>
            </div>
        </template>
        <div class="go-index-btn bgs100" @click="goIndex"></div>
    </div>
    <receive-ticket :show="showReceiveTicket" @close="showReceiveTicket=false"></receive-ticket>
  </div>
</template>

<script>
import { returnIsIOS } from '@/common/js/util';
import receiveTicket from '@/components/popup/glory/receiveTicket';
import list from '@/api/list';
import zybList from '@/api/zyblist';
export default {
    inject: [
        'openFullLoading',
        'closeFullLoading',
        'showToast',
        'updateUserInfo'
    ],
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    data () {
        return {
            showBox: false,
            list: [],
            list2: [],
            data: {},
            is_android: false,
            showReceiveTicket: false
        }
    },
    filters: {
        zeroFilter(num) {
            if(num) {
                let regexp=/(?:\.0*|(\.\d+?)0+)$/;
                return num.replace(regexp,'$1')
            } else {
                return num;
            }
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        exchange(item) {
            if(+item.times <= 0) {
                this.showToast('可兑换次数不足');return
            }
            if(+this.data.money < +item.price) {
                this.$emit('openBalanceConfirm');
                return
            }
            this.$emit('exchange', item.id);
        },
        // 获取兑换配置列表
        getConfigList() {
            list.userTicketConfig().then((res) => {
                this.list = res.data.ticketConfigList;

                // 处理看视频合成碎片的数据
                this.list2 = [ res.data.pieceItem ];

                // this.list = res.data.pieceItem;
            }).catch(() => { })
        },
        // 获取渠道货币余额
        getPromoteMoney() {
            list.userChannelMoney().then((res) => {
                this.data = res.data;
            })
        },
        goIndex() {
            if(returnIsIOS()) {
                this.close();
                window.webkit.messageHandlers.gotoRecommond.postMessage('');
            } else {
                try {
                    android.jump('HOME');
                } catch(err) {
                    console.log('h5退出');
                    window.parent.postMessage({
                        event:'sjs-exit',
                        data: ''
                    }, '*')
                }
            } 
        },
        goGiveTicket() {
            this.close();
            this.$router.push({ name: 'GiveTicket' });
        },
        videoBtnClick() {
            let state = this.list2[0].state;
            if(state == 0) {// 获取碎片
                android.playShangJinAd();
            } else if(state == 1) {// 合成
                this.assemble();
            } else if(state == 2) {}// 请明日继续
        },
        // 合成碎片方法
        assemble() {
            this.openFullLoading();
            list.ticketPieceAssemblePieces().then((res) => {
                this.closeFullLoading();
                if(res.code == 0) {
                    // this.showToast('合成成功');
                    this.showReceiveTicket = true;
                    setTimeout(() => {
                        this.getConfigList();
                        this.updateUserInfo();
                    }, 1000);
                }
            }).catch(() => { this.closeFullLoading(); })
        },
        // 播放广告成功之后，安卓会调用此方法通知h5
        // 此时去再次调用配置接口，获取最新的碎片信息
        onRewardVerify(jsonStr) {
            console.log("🚀 ~ onRewardVerify ~ jsonStr:", jsonStr);
            this.openFullLoading();
            zybList.userActionMobaCallback(jsonStr.customData, jsonStr.transID).then((res) => {
                this.closeFullLoading();
                setTimeout(() => { this.getConfigList(); }, 500);
            }).catch(() => { this.closeFullLoading(); })
            
        }
    },
    components: {
        receiveTicket
    },
    watch: {
        show(newV) {
            if(!newV) {
                this.showBox = false;
            } else {
                window.onRewardVerify = this.onRewardVerify;
                // 获取兑换配置列表
                this.getConfigList();
                // 获取渠道货币余额
                this.getPromoteMoney();
                setTimeout(() => { this.showBox = true; }, 100);
                try {
                    android.preloadShangJinAd();
                    this.is_android = true;
                } catch {
                    console.log('h5环境');
                }
            }
        }
    }
}
</script>

<style lang='scss' scoped>
    .exchange-ticket-wrapper {
        width: 100%;
		height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
        background: rgba(0, 0, 0, 0.6);
		z-index: 100;
        .exchange-ticket-box {
            width: 100%;
            min-height: 2.38rem;
            background: #fff;
            background-image: url('https://osscdn.zhuayoubao.com/sjs-h5-image/2023-08/glory/exchange-ticket-bg%402x.png');
            background-size: 100% 4.71rem;
            background-repeat: no-repeat;
            border-radius: 0.18rem 0.18rem 0 0;
            position: absolute;
            padding: 0.64rem 0.14rem 0.47rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            transition: all 0.3s ease-out;
            bottom: 0;
            .give-ticket {
                display: flex;
                align-items: center;
                position: absolute;
                top: 0.18rem;
                right: 0.2rem;
                .text {
                    font-size: 0.12rem;
                    font-family: PingFangSC, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    line-height: 0.15rem;
                }
                .more {
                    width: 0.15rem;
                    height: 0.15rem;
                    background-image: url('./../../../assets/glory/more-white@2x.png');
                }
            }
            .balance {
                font-size: 0.14rem;
                font-family: PingFangSC-Semibold, PingFang SC;
                font-weight: 600;
                color: #FFFFFF;
                line-height: 0.2rem;
                margin-bottom: 0.2rem;
                width: 100%;
                text-align: left;
                padding-left: 0.06rem;
            }
            .item {
                width: 100%;
                height: 0.78rem;
                background: #FFFFFF;
                border-radius: 0.08rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0.16rem;
                margin-bottom: 0.14rem;
                .left {
                    display: flex;
                    align-items: flex-start;
                    .icon {
                        width: 0.42rem;
                        height: 0.42rem;
                        margin-right: 0.1rem;
                        background-image: url('./../../../assets/glory/ticket-icon@2x.png');
                    }
                    span {
                        padding-top: 0.08rem;
                        font-size: 0.14rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.2rem;
                    }
                }
                .right {
                    position: relative;
                    bottom: 0.02rem;
                    // padding-top: 0.06rem;
                    .btn {
                        width: 0.7rem;
                        height: 0.26rem;
                        background: linear-gradient(180deg, #FFE74D 0%, #FB9324 100%);
                        border-radius: 0.14rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.12rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #CE5F01;
                    }
                    span {
                        font-size: 0.1rem;
                        color: #CE5F01;
                        line-height: 0.1rem;
                        position: absolute;
                        top: 0.34rem;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 200%;
                        text-align: center;
                    }
                }
            }
            .item2 {
                .icon {
                    width: 0.4rem;
                    height: 0.4rem;
                    margin-right: 0.1rem;
                    background-image: url('./../../../assets/glory/ticket-icon@2x.png');
                }
                .content {
                    flex: 1;
                    display: flex;
                    align-items: flex-start;
                    flex-direction: column;
                    .name {
                        font-size: 0.13rem;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 500;
                        color: #000000;
                        line-height: 0.19rem;
                        margin-bottom: 0.02rem;
                    }
                    .desc {
                        font-size: 0.12rem;
                        font-family: PingFangSC, PingFang SC;
                        font-weight: 400;
                        color: #999999;
                        line-height: 0.12rem;
                    }
                }
                .right {
                    .btn {

                    }
                    .btn.get-pieces {
                        background: #fff;
                        border: 1px solid #CE5F01;
                        color: #CE5F01;
                    }
                    .btn.go-on-tomorrow {
                        background: linear-gradient(180deg, #E3E3E3 0%, #CCCCCC 100%);
                        color: #989898;
                    }
                }
            }
            .go-index-btn {
                margin-top: 0.15rem;
                width: 2.4rem;
                height: 0.47rem;
                background-image: url('./../../../assets/glory/go-index-btn@2x.png');
            }
        }
        .exchange-ticket-box.in {
            bottom: -3.38rem !important;
        }
        
    }
    .op0 {
        opacity: 0;
    }
</style>
