import alert from './alert/alert'
import confirm from './confirm/confirm'
// import loading from './loading/loading'

/**
 * @type {import('.').XianwanUI}
 */
const xianwanUI = {
  // eslint-disable-next-line no-unused-vars
  install (Vue, options) {
    Vue.$log.debug('xianwan UI 注入')
    Vue.prototype.$xwDialog = this.dialog
  },
  dialog: {
    alert,
    confirm,
    // loading
  }
}

export default xianwanUI
