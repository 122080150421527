<template>
    <div class="balance-confirm-box-all" v-show="show">
        <div class="fadeIn out">
            <div class="confirm-box">
                <div class="desc">余额不足无法兑换！<br/>试玩游戏即可获得更多红包</div>
                <div class="btn-group">
                    <div class="btn cancel" @click="close">取消</div>
                    <div class="btn confirm" @click="confirm">确定</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { returnIsIOS } from '@/common/js/util';
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        close() {
            this.$emit('close');
        },
        confirm() {
            if(returnIsIOS()) {
                this.close();
                // window.webkit.messageHandlers.back.postMessage('');
                window.webkit.messageHandlers.gotoRecommond.postMessage('');
            } else {
                try {
                    android.finishPage();
                } catch(err) {
                    console.log('h5退出');
                    window.parent.postMessage({
                        event:'sjs-exit',
                        data: ''
                    }, '*')
                }
            }
            
        }
    }
}
</script>

<style lang="scss" scoped>
    .balance-confirm-box-all {
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.64);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        .out {
            width: 100%;
            height: 100%;
            background: transparent;
            position: relative;
            .confirm-box {
                width: 2.95rem;
                height: 1.82rem;
                background: #FFFFFF;
                border-radius: 0.15rem;
                position: absolute;
                top: 45%;
                left: 50%;
                transform: translate(-50%, -50%);
                padding-top: 0.48rem;
                display: flex;
                align-items: center;
                flex-direction: column;
                .desc {
                    font-size: 0.14rem;
                    color: #191A38;
                    line-height: 0.22rem;
                    text-align: center;
                    margin-bottom: 0.26rem;
                }
                .btn-group {
                    display: flex;
                    align-items: center;
                    .btn {
                        width: 1.19rem;
                        height: 0.4rem;
                        border-radius: 0.26rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 0.15rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                    }
                    .cancel {
                        border: 1px solid rgba(0,0,0,0.45);
                        color: rgba(0,0,0,0.45);
                    }
                    .confirm {
                        background: linear-gradient(180deg, #FFD14D 0%, #FB9324 100%);
                        color: #fff;
                        margin-left: 0.24rem;
                    }
                }
            }
        }
    }
</style>