import axios from 'axios'
import Vue from 'vue'
import md5 from 'js-md5';
import config from '../common/js/config';
// import { deepCopy } from '../common/js/util';

axios.interceptors.request.use(requestFunction, error => {
  Vue.$log.debug(error)
  return Promise.reject(error)
})

function requestFunction(request) {
  
  if((request.data && request.data.zyb) || (request.params && request.params.zyb) || request.url == '/common/uploadImg') {
    request = returnZybRequest(request);
    request.baseURL = process.env.NODE_ENV === 'development' ? config.zybBaseUrlDev : config.zybBaseUrlProd;
  } else {
    request = returnSjsRequest(request);
    let str = sessionStorage.getItem('originSearchStr');
    request.url+=str;
  }
  
  return request
}

function returnZybRequest(request) {
  // request.data需要传递3个内容1.channel2.token3.pk
  let zybRequestParam = JSON.parse(sessionStorage.getItem('originSearch'));
  let zybKeycode = sessionStorage.getItem('zybKeycode');

  request.headers['keycode'] = zybKeycode;
  request.headers['userid'] = zybRequestParam.appsign;
  if(request.url == '/common/uploadImg') {// 上传文件接口特殊处理

    let {file, type} = request.data;
    var formData = new FormData();  
    formData.append('file', file);
    if(type) formData.append('type', type);
    request.data = formData;

    return request
  }
  
  return request
}

function returnSjsRequest(request) {
  if(request.url == '/uploadImg') {// 上传文件接口特殊处理
    let {file, type} = request.data;
    var formData = new FormData();  
    formData.append('file', file);
    if(type) formData.append('type', type);
    request.data = formData;

    return request
  } else {
    return request
  }
}