import axios from 'axios'
import './request'
import './response'

var protocolStr = document.location.protocol;
if(process.env.NODE_ENV === 'development'){
  // axios.defaults.baseURL = 'http://192.168.10.119:9191/httmoba/api'
  // axios.defaults.baseURL = `${protocolStr}//mobadev.zhuayoubao.com/httmoba/api`   // 测试地址
  axios.defaults.baseURL = `https://mobaapi.zhuayoubao.com/httmoba/api`              // 正式环境
} else {
  axios.defaults.baseURL = `https://mobaapi.zhuayoubao.com/httmoba/api`              // 正式环境
  // axios.defaults.baseURL = 'http://192.168.10.40:9191/httmoba/api'
}

axios.defaults.withCredentials = true
// axios.defaults.headers["Content-Type"] = 'application/x-www-form-urlencoded'
axios.defaults.headers["Content-Type"] = 'application/json'