 <template>
  <transition name="confirm-fade">
    <div class="shade" v-show="showFlag">
      <div class="loading-module-cont" :style="{backgroundColor: textBgColor}">
        <span class="loading-module-text" v-html="text"></span>
      </div>
    </div>
    
  </transition>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    delay: {
      type: Number,
      default: 1500
    },
    textBgColor: {
      type: String,
      default: 'rgba(0,0,0,0.8)'
    }
  },
  data() {
    return {
      showFlag: false
    };
  },
  methods: {
    show() {
      if(this.timer) {
        clearTimeout(this.timer);
      }
      this.showFlag = true;
      this.timer = setTimeout(() => {
        this.showFlag = false;
      }, this.delay);
    },
    hide() {
      this.showFlag = false;
    },
    showNoHide() {
      this.showFlag = true;
    },
  }
};
</script>
<style lang="scss" scoped>

.shade {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background: rgba(0,0,0,0.5);
  z-index: 998;
  .loading-module-cont {
    position: fixed;
    top: 50%;
    left: 50%;
    padding: 0.08rem 0.16rem;
    line-height: 1.5;
    transform: translate(-50%, -50%);
    border-radius: 0.03rem;
    
    word-wrap: break-word;
    font-size: 0;
    color: #fff;

    text-align: center;
    display: inline-table;
    max-width: 80%;

    &.toast-fade-enter-active {
      animation: toast-fadein 0.3s;
      .toast-content {
        animation: toast-zoom 0.3s;
      }
    }

    .loading-module-text{
      font-size: 0.12rem;
    }
  }
}
  
  @keyframes toast-fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes toast-zoom {
    0% { transform: scale(0); }
    50% { transform: scale(1.1); }
    100% { transform: scale(1); }
  }
</style>
